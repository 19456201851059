<template>
    <v-container>
        <v-row v-if="favouriteBrands">
            <v-col class="text-center" v-for="brand in favouriteBrands" :key="brand.id">
                <v-btn text @click="searchByBrand(brand.name_en)">{{brand.name_en}}</v-btn>
            </v-col>

            <v-col class="text-center">
                <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn text v-bind="attrs" v-on="on">
                            {{$t('other')}}
                            <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            v-for="(brand, index) in restBrands"
                            :key="index"
                            @click="searchByBrand(brand.name_en)"
                        >
                            <v-list-item-title>{{brand.name_en}}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>

        <v-divider v-if="favouriteBrands"></v-divider>

        <v-row>
            <v-col cols="8" offset="2">
                <v-card>

                    <v-form @submit.prevent="search">
                        <v-card-text>
                            <v-row>
                                <v-col cols="4">
                                    <v-autocomplete
                                        clearable="true"
                                        :label="$t('search_by_group')"
                                        :items="getDocumentGroupsBranding"
                                        v-model="documentGroup"
                                    >
                                    </v-autocomplete>
                                </v-col>

                                <v-col cols="4">
                                    <v-text-field
                                        clearable="true"
                                        :label="$t('search_by_keyword')"
                                        type="text"
                                        v-model="keywords"
                                    />
                                </v-col>

                                <v-col cols="4">
                                    <v-btn id="searchButton" type="submit" color="info" class="mt-4">
                                        <v-icon dark left>mdi-magnify</v-icon>
                                        {{ $t('search') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-form>

                </v-card>
            </v-col>
        </v-row>

        <imagesList v-if="images" :images="images" page="search" docs="branding"></imagesList>

        <v-snackbar id="snackbar" :color=snackbarColor :top=snackbarTop v-model="snackbar">
            {{ snackbarMessage }}

            <v-btn text @click="snackbar=false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
import {mapGetters, mapState} from 'vuex'

import imageService from '@/services/ImageService.js'
import brandService from '@/services/BrandService.js'
import lightboxService from '@/services/LightboxService.js'

import imagesList from '@/components/ImagesList'

export default {
    name: 'Branding',

    components: {
        imagesList
    },

    computed: {
        ...mapState(['user', 'lightbox', 'isLoggedIn', 'isVisitor']),
        ...mapGetters(['getName', 'getDocumentGroupsBranding']),
    },

    created() {
        // Route guard
        if (!this.isLoggedIn) {
            this.$store.dispatch('logout')
            this.$router.push('/login')
        }

        this.init()
    },

    data() {
        return {
            keywords: '',
            documentGroup: '',
            images: [],

            brands: [],
            favouriteBrands: [],
            restBrands: [],

            snackbar: false,
            snackbarTop: true,
            snackbarColor: '',
            snackbarMessage: '',
        }
    },

    methods: {
        init() {
            brandService.favourite()
                .then(response => {
                    this.favouriteBrands = response.data
                })
                .catch(() => {
                    this.snackbar = true
                    this.snackbarColor = "error"
                    this.snackbarMessage = this.$t('invalid_data')
                })

            brandService.rest()
                .then(response => {
                    this.restBrands = response.data
                })
                .catch(() => {
                    this.snackbar = true
                    this.snackbarColor = "error"
                    this.snackbarMessage = this.$t('invalid_data')
                })

            // Check if there is a current lightbox. If not: create a default one
            lightboxService.findLast({
                user_id: this.user.id
            })
                .then(response => {
                    // Set lightbox in store
                    this.$store.dispatch('setLightbox', response.data)
                })

            this.splash()
        },

        splash() {
            imageService.splash({
                isVisitor: false,
                isBranding: true,
            })
                .then(response => {
                    this.images = response.data
                    this.filter()
                })
                .catch(() => {
                    this.snackbar = true
                    this.snackbarColor = "error"
                    this.snackbarMessage = this.$t('no_results')

                    this.images = []
                })
        },

        search() {
            imageService.all(
                {
                    documentGroup: this.documentGroup,
                    keywords: this.keywords,
                    isBranding: true,
                }
            )
                .then(response => {
                    this.images = response.data

                    if (this.getName == "MNP") {
                        if (this.isVisitor) {
                            let allowedImages = this.images
                                .filter(image => image.published == "Yes")
                                .filter(image => image.category == "Public")

                            this.images = allowedImages
                        }
                    }

                    this.filter()
                })
                .catch(() => {
                    this.snackbar = true
                    this.snackbarColor = "error"
                    this.snackbarMessage = this.$t('no_results')

                    this.images = []
                })
        },

        searchByBrand(brand) {
            this.keywords = brand
            this.search()
        },

        filter() {
            // Sort
            this.images.sort(function(a, b) {
                // NULL values should be replaced
                if (!a.variety) {
                    a.variety = ''
                }
                if (!b.variety) {
                    b.variety = ''
                }

                let varietyA = a.variety.toUpperCase()
                let varietyB = b.variety.toUpperCase()

                if (varietyA < varietyB) {
                    return -1
                }
                if (varietyA > varietyB) {
                    return 1
                }
                return 0
            })
        },
    },
}
</script>
